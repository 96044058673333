import { axiosRetryWrapper } from '@/utils/axiosWrapper';

export class GraphQLService {
  private apiGql = process.env.VUE_APP_GRAPHQL_HTTP ?? '';

  public async getConnections(orgId: string) {
    try {
      const vars = {
        orgId,
      };
      const client = axiosRetryWrapper('/', { retries: 3, status_code: 500 }); // define the status code of the error to retry
      const response = await client.post(
        this.apiGql,
        {
          query: `
              query GetConnections($orgId: ID!) {
                connections(orgId: $orgId) {
                  id
                  provider
                  lastSyncSEC
                  isSetupComplete
                  isDisabled
                  isDeleted
                  syncStatus {
                    status
                    lastSyncCompletedSEC
                    errors
                    warnings
                    isRunning
                  }
                  name
                  subAccountName
                }
              }
            `,
          variables: vars,
        },
        {
          withCredentials: true,
        }
      );
      return response.data?.data?.connections ?? [];
    } catch (e) {
      console.log('Problem getting connections', e);
    }
  }
}
