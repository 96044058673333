<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectGemini')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <p>
            We recommend that the API credentials you are sharing with us be
            <b>read-only</b>.
          </p>
          <v-text-field label="API Key" v-model="apiKey"></v-text-field>
          <v-text-field label="API Secret" v-model="apiSecret"></v-text-field>
          <v-autocomplete
            v-model="exchangeContactId"
            :items="contacts"
            :label="$t('_exchangeContact')"
            item-text="name"
            item-value="id"
            persistent-hint
          ></v-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: ['refresh'],
  data() {
    return {
      apiKey: '',
      apiSecret: '',
      showDialog: false,
      exchangeContactId: '',
    };
  },
  computed: {
    canSave() {
      return this.apiKey && this.apiSecret && this.exchangeContactId;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.apiKey = '';
      this.apiSecret = '';
      this.exchangeContactId = '';
    },
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createGemini: {
            apiKey: this.apiKey,
            apiSecret: this.apiSecret,
            exchangeContactId: this.exchangeContactId,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create) {
                success
                errors
                message
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.resetForm();
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>
